.vistel-dnevna
{
	.weather-forecast
	{
		display: flex;
		align-items: center;
		grid-gap: 30px;
		width: 90%;

		.map,
		.weather
		{
			opacity: 0;
			width: 50%;
			transition: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
		}

		.map svg
		{
			width: 100%;
			height: 100%;
			max-height: 80vh;

			.pin
			{
				transition: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
			}
		}
	}

	.weather
	{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		opacity: 1;

		.city-card
		{
			display: flex;
			flex-direction: column;
			max-width: 400px;
			width: 100%;
			height: 210px;
			text-align: center;
			margin: 0 auto;
			opacity: 0;
			transition: .5s cubic-bezier(0.25, 0.1, 0.25, 1);

		&:nth-child(n+2)
		{
			position: absolute;
			top: calc(50% - 120px);
			transform: translateY(-50%);
		}

		&:nth-child(2n+1)
		{
			position: absolute;
			top: calc(50% + 120px);
			transform: translateY(-50%);
		}

		&:nth-child(odd)
		{
			.name
			{
				background-color: #FDDE82;
				color: #000;

				p
				{
					color: #000;
				}
			}
		}
			
			.name
			{
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 0;
				background-color: #35609A;
				color: #fff;
				overflow: hidden;
				transition: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);

				p
				{
					font-size: 2.4rem;
					letter-spacing: 2px;
					color: inherit;
					color: #fff;
					margin-bottom: 0;
					opacity: 0;
					transition: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
					transition-delay: 0s;
				}
			}

		&.active .name
		{
			height: 60px;

			p
			{
				transition: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
				transition-delay: 0.5s;
				opacity: 1;
			}
		}

			.info
			{
				display: flex;
				width: 100%;

				> div
				{
					width: 50%;
					height: 150px;
					height: 0;
					overflow: hidden;
					display: flex;
					justify-content: center;
					align-items: center;
					transition: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
					transition-delay: 0s;
				}

				.icon
				{
					background-color: #fff;

					img
					{
						max-width: 100%;
						max-height: 100%;
						width: 100%;
						height: 100%;
						opacity: 0;
					}
				}

				.temp
				{	
					-webkit-backdrop-filter: saturate(180%) blur(20px);
					backdrop-filter: saturate(180%) blur(20px);
					background: rgba(255,255,255,.4);
					font-size: 5rem;
				}
			}

			&.active .info
			{
				> div
				{
					height: 150px;
					transition-delay: 0.5s;
				}

				.icon
				{
					img
					{
						opacity: 1;
						transition-delay: 0.5s;
					}
				}
			}
		}
	}

	.active
	{
		.weather,
		.map 
		{
			opacity: 1;
		}

		@for $i from 1 through 8 {
			.map svg #p#{$i}
			{
				animation-name: pinToggle;
				animation-duration: 5s;
				animation-delay: ($i - 1) * 5s;
			}
		}

		.map svg #p8
		{
			animation-fill-mode: forwards;
		}

		.city-card
		{
			opacity: 1;
			visibility: visible;
		}
	}


	@keyframes pinToggle {
		0% {
			opacity: 0;
		}

		10% {
			opacity: 1;
		}

		90% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}

	// Map

	.map-forecast
	{
		display: flex;
		align-items: center;
		justify-content: center;
		grid-gap: 30px;
		width: 90%;
		z-index: 1;

		svg
		{
			width: auto;
			height: 90vh;
		}

		.current-date
		{
			position: absolute;
			top: 60px;
			left: calc(50% + 300px);
			color: #35609A;
			font-size: 3rem;
			font-weight: 700;

			h1
			{
				font-size: 4vh;
				font-weight: 600;
			}

			h3 
			{
				font-size: 3vh;
				font-weight: 500;
			}
		}
	}

	.europe
	{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100vh;
		z-index: 1;

		svg
		{
			min-width: 100vw;
			height: auto;
		}

		.europe-bg
		{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: auto;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			img
			{
				width: 100%;
				height: 100%;
				min-width: unset;
			}
		}

		.cities
		{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: auto;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 1;

			svg
			{
				width: auto;
				height: 100%;
				min-width: unset;
			}
		}
	}


	.balkan
	{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100vh;
		z-index: 1;

		svg
		{
			min-width: 100vw;
			height: auto;
		}

		.balkan-bg
		{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: auto;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			img
			{
				width: 100%;
				height: 100%;
				min-width: unset;
			}
		}

		.cities
		{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: auto;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 1;

			svg
			{
				width: auto;
				height: 100%;
				min-width: unset;
			}
		}
	}

	.temp
	{
		position: relative;

		.temp-header
		{
			display: inline-flex;
			padding: 10px 100px 10px 10px;
			background-color: rgba(0, 0, 0, 0.6);
			margin-bottom: 10px;
			font-size: 26px;
			font-weight: 600;
			color: #fff;
			text-transform: uppercase;
		}

		.temp-boxes
		{
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			grid-gap: 10px;

			.temp-box
			{
				width: 320px;
				-webkit-backdrop-filter: saturate(300%) blur(20px);
				backdrop-filter: saturate(300%) blur(20px);
				background: rgba(255,255,255,.85);

				&_content
				{
					padding: 20px;
				}

				&_header
				{
					background-color: #f2a82f;
					height: 55px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-transform: uppercase;
					font-size: 26px;
					color: #fff;
					text-shadow: 0px 3px 10px rgba($color: #000000, $alpha: 0.1);
					font-weight: 600;
				}

				&_icon
				{
					text-align: center;

					img 
					{
						width: 180px;
					}
				}

				&_temp
				{
					display: flex;
					flex-direction: column;
					justify-content: center;

					> div 
					{
						display: grid;
						grid-template-columns: 1fr 0.4fr 1fr;
						justify-content: center;
						align-items: center;
						align-self: center;
						width: 100%;
						font-size: 60px;
						font-weight: 600;

						span
						{
							width: 1px;
							height: 30px;
							background-color: #ccc;
							display: inline-flex;
							justify-content: center;
						}

						.min
						{
							justify-content: flex-end;
							opacity: .6;
						}

						.max
						{
							justify-content: flex-start;
						}

						> div 
						{
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}

				&_desc
				{
					margin-top: 10px;
					font-size: 17px;
					font-weight: 600;
					line-height: 20px;
					text-align: center;
				}
			}
		}
	}
}