@media print {
	@page { margin: 1cm 0cm !important; }
	@page { size: auto !important;  margin: 8mm 1cm !important; }

    .modern-design .aside,
    .aside.open,
    .modern-design .header,
	.mobile-header
    {
        display: none !important;
		opacity: 0;
		visibility: hidden;
    }

	.content
	{
		display: block !important;
	}

	.app 
	{
		display: block !important; 
	}

	.wrapper
    {
		width: 100% !important;
        padding-left: 0 !important;
		padding: 0 !important;
		display: block !important;
		left: 0 !important;
    }

	.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl,
	.page,
	.card-header,
	.card-body,
	.card-footer
	{
		padding: 0 !important;
	}

	.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl
	{
		max-width: unset !important;
	}

	.card
	{
		padding: 0 !important;
		margin: 0 !important;
		box-shadow: unset !important;
	}

	.card-header
	{
		display: block !important;
	}

	.card-header .card-label,
	.card-header .card-actions
	{
		display: inline-block !important; 
		width: 50% !important;
	}

	.card-header .card-actions
	{
		text-align: right !important;
	}

	.fs-5
	{
		font-size: 14px !important;
	}

    #print 
    {
        .card-footer-right
        {
            display: none !important; 
        }
    }

	.footer,
	footer
	{
		display: none !important;
	}

    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl
    {
        max-width: unset !important;
        width: 100% !important;
    }

	body, html
	{
		display: block !important;
	}
}