/* Custom CSS */

@font-face {
	font-family: 'NK767';
	src: url('../fonts/nk767.otf');
	font-weight: 400;
}

@font-face {
	font-family: 'Blogger Sans';
	src: url('../fonts/blogger_sans.otf');
	font-weight: 400;
}

@font-face {
	font-family: 'Blogger Sans';
	src: url('../fonts/blogger_sans_medium.otf');
	font-weight: 500;
}

@font-face {
	font-family: 'Blogger Sans';
	src: url('../fonts/blogger_sans_bold.otf');
	font-weight: 600;
}

.text-rain {
    color: #2a84c7;
	position: absolute;
	top: 20px;
	left: calc(50% + 20px);
	font-weight: 600;
}

.custom-weather-select small
{
	max-width: 120px;
	width: 120px;
	white-space: break-spaces;
    color: #323232 !important;
	margin-left: 5px;
	font-weight: 600 !important;
}

.css-13cymwt-control,
.css-t3ipsp-control,
.css-16xfy0z-control
{
    background-color: #f8f9fa !important;
    border: 1px solid #f8f9fa !important;
    border-radius: 1rem !important;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.075) !important;
    color: #323232 !important;
    display: block;
	font-weight: 600 !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.css-16xfy0z-control,
.css-1jpmlr0-control:hover
{
	background-color: #e9ecef!important;
    opacity: 1;
}

.css-1hb7zxy-IndicatorsContainer
{
	background-color: #e9ecef !important;
    background-color: #e9ecef !important;
    border: 1px solid #f8f9fa !important;
    border-radius: 1rem !important;
    color: #323232 !important;
	box-shadow: none !important;
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    white-space: nowrap;
	border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    margin-left: -1px !important;
}

.css-tj5bde-Svg
{
	fill: #323232!important;
}

.css-1u9des2-indicatorSeparator
{
	display: none !important;
}


.select__option--is-selected,
.select__option--is-focused
{
	color: #fff !important;
}

.tool-tip
{
    
}

    .tool-tip::after
    {
        content: attr(data-tooltip);
        position: absolute;
        top: 0;
        padding: 10px;
        font-size: 10px;
        top: -40px;
        white-space: nowrap;
        background-color: #fff;
        border-radius: 10px;
        text-align: center;
        font-weight: 300;
        opacity: 0;
        transition: 0.2s linear;
    }

    .tool-tip:hover::after
    {
        opacity: 1;
    }

.template-download
{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    button
    {
        display: flex;
        width: 300px;
        height: 70px;
        justify-content: center;
        align-items: center;
    }
}

.capitalize
{
    text-transform: capitalize;
}

.start-template
{
    width: 100px;
    height: 100px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;

    svg
    {
        width: 100px;
    }
}