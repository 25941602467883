.sitel-vecerna
{
	font-family: 'NK767';

	.balkan
	{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100vh;
		z-index: 1;
	}
	
	.image-bg
	{
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		img 
		{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.active
	{

		.delayed-screen
		{
			opacity: 1;
			transition-delay: 0.5s !important;
		}
	}
	
	.shorter-delay.active
	{

		.delayed-screen
		{
			opacity: 1;
			transition-delay: 0.1s !important;
		}
	}

	.forecast-5days
	{
		position: relative;
		max-width: 75%;
		width: 95%;
		padding: 0 7%;
		margin: 0 0 0 auto;
		transition: 0.5s ease-in-out;
		z-index: 1;

		h1
		{
			text-align: right;
			color: #fff;
			text-transform: uppercase;
			margin-bottom: 20px;
			text-shadow: 0 2px 5px rgba($color: #000000, $alpha: .3);
			transition: 0.3s ease;
			font-size: 54px;

			span
			{
				font-size: 44px;
				transition: 0.3s ease;
			}
		}

		&-boxes
		{
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			grid-gap: 20px;
			align-items: flex-start;
			border-top: 5px solid rgba($color: #fff, $alpha: .5);

			&_box
			{
				width: 100%;
				background-color: rgba(22, 45, 255, .5);

				&_header
				{
					width: 100%;
					padding: 10px 10px;
					font-size: 52px;
					text-transform: capitalize;
					// background-color: rgba($color: #fff, $alpha: .3);
					color: #fff;
					text-transform: uppercase;
					text-shadow: 0 2px 5px rgba($color: #000000, $alpha: .2);
				}

				&_max-temp
				{
					width: 100%;
					padding: 10px 10px;
					grid-gap: 5px;
					display: grid;
					grid-template-columns: 1fr 1fr;
					justify-content: center;
					align-items: center;
					font-size: 70px;
					background-color: rgba(255, 255, 255, 0.3);
					color: #fff;
					font-weight: 400;
					text-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

					&.skopje 
					{
						grid-template-columns: 1fr;

						p 
						{	
							text-align: center;
						}
					}

					p
					{
						background: rgb(209,44,31);
						background: linear-gradient(90deg, rgba(209,44,31,1) 0%, rgba(235,131,50,1) 50%, rgba(209,44,31,1) 100%);
						text-align: center;
						margin-top: 0;
						margin-bottom: 0;
					}
				}

				&_icon
				{
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 190px;
					// background-color: rgba($color: #fff, $alpha: .2);

					img
					{
						max-width: 220px;
						max-height: 220px;
					}
				}

				&_min-temp
				{
					width: 100%;
					padding: 10px 10px;
					grid-gap: 5px;
					display: grid;
					grid-template-columns: 1fr 1fr;
					justify-content: center;
					align-items: center;
					font-size: 70px;
					background-color: rgba(255, 255, 255, 0.3);
					color: #fff;
					font-weight: 400;
					text-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

					&.skopje 
					{
						grid-template-columns: 1fr;

						p 
						{	
							text-align: center;
						}
					}

					p
					{
						text-align: center;
						background: rgb(22,50,139);
						background: linear-gradient(90deg, rgba(22,50,139,1) 0%, rgba(46,88,180,1) 50%, rgba(22,50,139,1) 100%);
						margin-top: 0;
						margin-bottom: 0;
					}
				}
			}
		}
	}

}

.sitel-vecerna .forecast-5days h1
{
	opacity: 0;
}

.sitel-vecerna .active .forecast-5days h1
{
	animation-name: fadeIn;
  	animation-duration: 0.5s;
	// animation-delay: 2.5s;
	animation-fill-mode: forwards;
}

.sitel-vecerna .forecast-5days h1 span
{
	opacity: 0;
}

.sitel-vecerna .active .forecast-5days h1 span
{
	animation-name: fadeIn;
  	animation-duration: 0.5s;
	animation-delay: 0.2s;
	animation-fill-mode: forwards;
}

.sitel-vecerna .forecast-5days-boxes_box
{
	opacity: 0;
}

.sitel-vecerna .forecast-5days 
{
	opacity: 0;
}

.sitel-vecerna .active .forecast-5days 
{
	animation-name: fadeIn;
	animation-duration: 0.5s;
	// animation-delay: 2.5s;
	animation-fill-mode: forwards;
}

.sitel-vecerna .active .forecast-5days-boxes_box:nth-child(1) 
{ 
	animation-name: fadeIn;
	animation-duration: 0.5s;
	animation-delay: 0.5s;
	animation-fill-mode: forwards;
}

 .sitel-vecerna .active .forecast-5days-boxes_box:nth-child(2) 
{ 
	animation-name: fadeIn;
	animation-duration: 0.5s;
	animation-delay: 0.6s;
	animation-fill-mode: forwards;
}

 .sitel-vecerna .active .forecast-5days-boxes_box:nth-child(3) 
{ 
	animation-name: fadeIn;
	animation-duration: 0.5s;
	animation-delay: 0.7s;
	animation-fill-mode: forwards;
}

 .sitel-vecerna .active .forecast-5days-boxes_box:nth-child(4) 
{ 
	animation-name: fadeIn;
	animation-duration: 0.5s;
	animation-delay: 0.8s;
	animation-fill-mode: forwards;
}

 .sitel-vecerna .active .forecast-5days-boxes_box:nth-child(5) 
{ 
	animation-name: fadeIn;
	animation-duration: 0.5s;
	animation-delay: 0.9s;
	animation-fill-mode: forwards;
}


.sitel-vecerna #city-wrap
{
	opacity: 0;
}

.sitel-vecerna .active #city-wrap
{
	animation-name: fadeIn;
	animation-duration: 0.5s;
	animation-delay: 0.5s;
	animation-fill-mode: forwards;
}

@keyframes fadeIn {
	from {opacity: 0;}
	to {opacity: 1;}
}