.template 
{
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;

	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	font-family: 'Ubuntu', sans-serif;

	cursor: none;
}

.video-bg
{
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 0;

	img 
	{
		min-width: 100%;
		min-height: 100%;
		object-fit: cover;
	}
}

.transition-bg
{
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 10;
}

.download
{
	z-index: 100;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.slider
{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 100vh;

	.item
	{
		width: 100%;
		height: 100vh;
		overflow: hidden;
		opacity: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		visibility: hidden;
		transition: 0.5s ease-in;
		z-index: 1;

		.delayed-screen
		{
			opacity: 0;
			transition: 0.5s ease-in-out;
		}

		&.active
		{
			opacity: 1;
			visibility: visible;
			transition: 0.3s ease-in-out;

			.delayed-screen
			{
				opacity: 1;
				transition-delay: 0.5s;
			}
		}

		video:not(.icon) 
		{
			min-width: 100%;
			min-height: 100%;
		}
	}
}

.weather-forecast
{
	z-index: 1;
	position: relative;
}
