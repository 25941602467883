.lokalna-dnevna
{
	font-family: 'Blogger Sans';
	font-weight: 500;

	.top-title
	{
		position: absolute;
		top: 50px;
		left: 50px;
		display: flex;
		flex-direction: column;
		grid-gap: 5px;

		div
		{
			font-size: 36px;
			font-weight: 500;
			padding: 5px 25px 0px 25px;
			color: #fff;
			background-color: #262626;
		}

		div.www
		{
			background-color: #3C769C;
		}
	}
}

.forecast-city
{
	width: 100%;
	height: 390px;
	padding: 0 50px;
	z-index: 1;
	
	.forecast-city-wrap
	{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 390px;
	}

	.wind
	{
		width: 160px;
		padding: 0 10px;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		background-color: rgba($color: #000000, $alpha: .3);

		.wind-direction
		{
			margin: auto;
			padding: 15px 10px 15px 20px;
			border-left: 5px solid #3E7AA0;
		}

		.wind-speed
		{
			position: absolute;
			bottom: 0;
			line-height: 36px;
			font-size: 32px;
			font-weight: 500;
			color: #fff;
			padding: 0 10px 20px;
		}
	}

	.temperature
	{
		min-width: 240px;
		padding: 0 20px;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		background-color: #3E7AA0;

		h2
		{
			font-size: 95px;
			font-weight: 500;
			color: #fff;
			margin-top: 12px;
			margin-bottom: 0;
		}

		.temperature-feel
		{
			position: absolute;
			bottom: 0;
			padding: 0 10px 20px;
			color: #fff;
			font-size: 26px;
			font-weight: 500;
			text-align: center;
			line-height: 36px;
		}
	}

	.icon
	{
		width: 430px;
		padding: 0 20px;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		background-color: rgba($color: #000000, $alpha: .4);

		.description
		{
			position: absolute;
			bottom: 0;
			padding: 0 10px 20px;
			text-align: center;
			line-height: 36px;
			font-size: 28px;
			font-weight: 500;
			color: #fff;
		}
	}

	.data
	{
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: space-between;
		background-color: #E5E5E5;
		padding: 30px 30px;
		height: 100%;

		.title
		{
			width: 100%;

			h1
			{
				font-size: 70px;
				font-weight: 600;
				color: #464646;
				
				span
				{
					font-size: 45px;
					margin-left: 20px;
				}
			}
		}

		.data-information
		{
			display: flex;
			justify-content: space-between;
			text-align: center;

			> div
			{
				text-align: center;

				img
				{
					max-height: 70px;
					margin-bottom: 20px;
				}
			}

			p
			{
				font-size: 26px;
				font-weight: 500;
				color: #949494;
				margin-bottom: 0;

				b
				{
					display: flex;
					font-size: 32px;
					font-weight: 500;
					color: #464646;
					margin-top: 20px;
					justify-content: center;
				}
			}


		}
	}

}

.forecast-city .top-title
{
	opacity: 0;
	transition: 0.3s ease;
}

.active .forecast-city .top-title
{
	opacity: 1;
	transition-delay: 0.5s;
}

.forecast-city .wind,
.forecast-city .temperature,
.forecast-city .icon,
.forecast-city .data
{
	opacity: 0;
	transition: 0.3s ease;
}

.active .forecast-city .wind,
.active .forecast-city .temperature,
.active .forecast-city .icon,
.active .forecast-city .data
{
	opacity: 1;
}

.active .forecast-city .wind { transition-delay: 0.5s; }
.active .forecast-city .temperature { transition-delay: 0.55s; }
.active .forecast-city .icon { transition-delay: 0.6s; }
.active .forecast-city .data { transition-delay: 0.65s; }


.lokalna-dnevna #city-wrap
{
	opacity: 0;
}

.lokalna-dnevna .active #city-wrap
{
	animation-name: fadeIn;
	animation-duration: 0.5s;
	animation-delay: 0.5s;
	animation-fill-mode: forwards;
}

@keyframes fadeIn {
	from {opacity: 0;}
	to {opacity: 1;}
}


.forecast-cities
{
	width: 100%;
	padding: 0 230px;
	z-index: 1;

	.forecast-cities-wrap
	{
		display: flex;
		justify-content: center;
		align-items: flex-start;
		grid-gap: 5px;
	}

	.city-box
	{
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: rgba($color: #000000, $alpha: .3);

		.city-name
		{
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px 0 6px;
			background-color: #FEA40A;
			font-size: 26px;
			font-weight: 600;
			color: #202020;
			text-transform: uppercase;
		}

		.weather-icon
		{
			padding: 20px 0;
		}

		.weather-description
		{
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 0 6px;
			background-color: #2A2B2D;
			font-size: 20px;
			font-weight: 500;
			color: #fff;
			text-transform: lowercase;
			margin-bottom: 5px;
		}

		.temperature
		{
			text-align: center;
			padding: 45px 10px 10px;
			font-size: 150px;
			line-height: 150px;
			font-weight: 500;
			color: #202020;
			background-color: #EDEDED;

			&.min_temp
			{
				color: #fff;
				background-color: #5388AA;
			}
		}

		.wind
		{
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 5px 0 3px;
			font-size: 20px;
			font-weight: 600;
			color: #fff;
			text-transform: uppercase;

			svg 
			{
				max-width: 18px;
				margin-right: 10px;
			}
		}
	}
}