.sitel-utrinska
{
	font-family: 'NK767';
	font-weight: 400;

	.image-bg
	{
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		img 
		{
			width: 100%;
			height: 100%;
			// object-fit: cover;
		}
	}

	.satelite
	{
		display: flex;
		justify-content: center;
		align-items: flex-start;
		width: 100%;
		height: 100vh;

		img { 
			width: 100% !important;
			height: unset !important;
			object-fit: unset !important;
		}
	}

	.utrinska-city-forecast
	{
		position: relative;
		max-width: 70%;
		width: 100%;
		padding: 0 10% 0 0;
		margin: 0 0 0 auto;
		opacity: 0;
		transition: 0.5s ease-in-out;
		z-index: 1;

		&_header
		{
			margin-bottom: 20px;
			text-shadow: 0 3px 8px rgba($color: #000000, $alpha: .2);
			box-shadow: 0 5px 10px rgba($color: #000000, $alpha: .15);
			background: rgb(100,200,235);
			background: linear-gradient(90deg, rgba(100,200,235,1) 0%, rgba(45,157,196,1) 50%, rgba(100,200,235,1) 100%);
			transition: 0.5s ease-in-out;

			.date
			{
				font-size: 55px;
			}
		}

		&_header,
		&_body
		{
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: #fff;
			color: #fff;
			font-size: 36px;
			padding: 10px 20px;
			text-transform: uppercase;

			.min-temp,
			.max-temp
			{
				line-height: 30px;
			}
		}

		&_body
		{
			flex-direction: column;
			background-color: transparent;
			grid-gap: 10px;
			padding: 0;

			&__city
			{
				position: relative;
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				padding: 0px 15px;
				color: #fff;
				font-size: 55px;
				transition: 0.5s ease-in-out;
				text-shadow: 0 3px 8px rgba($color: #000000, $alpha: .2);
				box-shadow: 0 5px 10px rgba($color: #000000, $alpha: .15);
				background: rgb(100,200,235);
				background: linear-gradient(90deg, rgba(100,200,235,1) 0%, rgba(45,157,196,1) 50%, rgba(100,200,235,1) 100%);

				&::before, &::after
				{
					z-index: -1;
					position: absolute;
					content: "";
					bottom: 6px;
					left: 0;
					width: 50%;
					top: 80%;
					max-width:300px;
					background: #000;
					-webkit-box-shadow: 0 7px 10px #000;
					-moz-box-shadow: 0 7px 10px #000;
					box-shadow: 0 7px 10px #000;
					-webkit-transform: rotate(-3deg);
					-moz-transform: rotate(-3deg);
					-o-transform: rotate(-3deg);
					-ms-transform: rotate(-3deg);
					transform: rotate(-3deg);
					opacity: 0;
					transition: .5s ease-in-out;
				}

				&::after
				{
					-webkit-transform: rotate(3deg);
					-moz-transform: rotate(3deg);
					-o-transform: rotate(3deg);
					-ms-transform: rotate(3deg);
					transform: rotate(3deg);
					right: 0;
					left: auto;
				}

				.min-temp,
				.max-temp
				{
					display: flex;
					align-items: center;
					font-size: 60px;
				}

			}
		}
		
	}

	// Animation
	.active
	{
		.utrinska-city-forecast
		{
			opacity: 1;
		}
	}

	.map-forecast
	{
		position: relative;
		z-index: 1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		grid-gap: 30px;
		width: 77%;
		margin: 0 0 0 auto;
		padding: 0 50px;

		.map
		{
			width: 100%;
			height: 100%;

			svg
			{
				max-width: 100%;
				max-height: 100%;
			}
		}

		.temperature
		{
			display: flex;
			flex-direction: column;
			grid-gap: 15px;

			&-min
			{
				padding: 0 10px;
				color: #006CFF;

				p:first-child
				{
					border-right: 2px solid rgba($color: #000000, $alpha: .1);
				}
			}

			&-max
			{
				padding: 0 10px;
				color: #E03434;

				p:first-child
				{
					border-right: 2px solid rgba($color: #000000, $alpha: .1);
				}
			}

			&-min,
			&-max
			{
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 400;
				height: 120px;
				font-size: 90px;
				line-height: 90px;
				padding: 30px 0;
				border-radius: 20px;
				background-color: rgba($color: #fff, $alpha: .6);

				p
				{
					display: block;
					text-align: center;
					margin-bottom: 0;
					width: 100%;
					padding: 0px 20px;
				}
			}

		}
	}

	.active
	{

		.delayed-screen
		{
			opacity: 1;
			transition-delay: 0.5s !important;
		}
	}

	.shorter-delay.active
	{

		.delayed-screen
		{
			opacity: 1;
			transition-delay: 0.1s !important;
		}
	}
}